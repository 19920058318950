const isBT = (oparam, vparam) => oparam === 'UM1' || oparam === 'UM2' || vparam === 'UM1' || vparam === 'UM2';

export const getExperimentId = (oparam, vparam, isContactDetailsTest) => {
    if (isContactDetailsTest) {
        if (isBT(oparam, vparam)) {
            return 'qcd-bt';
        }
        return 'qcd';
    }
    return null;
};

export default getExperimentId;
