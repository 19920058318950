const getKey = (id, values) => {
    return Object.keys(values).find((key) => key.includes(id));
};

const setFieldToDefaultForSchema = (schemaMatcher, fieldId, defaultValue, formResponseValues) => {
    if (formResponseValues[fieldId] === undefined && schemaMatcher) {
        formResponseValues[fieldId] = defaultValue;
    }
};

export const getValue = (id, values) => {
    const fieldName = Object.keys(values).find((key) => key.includes(id));
    return values[fieldName];
};

export const mutateFormValues = (values, definition, isPartnerAggsEdit) => {
    const formResponseValues = { ...values };

    const findElement = (item) => {
        return Object.keys(formResponseValues).find((element) => element.match(item));
    };

    if (isPartnerAggsEdit) {
        formResponseValues[findElement('marketing-permission')] = 'false';
        formResponseValues[findElement('future-soft-search-permission')] = 'false';
    }

    delete formResponseValues.preTaxIncomeDuration;
    delete formResponseValues.otherIncomeDuration;

    // `&` causes applications to get stuck in RAID - convert them to text prior to sending.
    if (getValue('occupation', values)) {
        const key = getKey('occupation', values);
        formResponseValues[key] = formResponseValues[key].replace(/&/g, 'and');
    }

    const isQcoOrQcd = definition.id.match(/qco/) || definition.id.match(/qcd/);

    // set other income amount to 0 if undefined for QCO  or QCD schemas as question is optional on form
    // different for QCO/QCD as field is post-fixed with amount as the question is hidden behind a yes/no radio
    setFieldToDefaultForSchema(
        isQcoOrQcd,
        'income-03:other-household-income:other-household-income-amount',
        '0',
        formResponseValues
    );

    // set other income to 0 if undefined for other schemas as question is optional on form
    setFieldToDefaultForSchema(
        !isQcoOrQcd,
        'income-03:other-household-income:other-household-income',
        '0',
        formResponseValues
    );
    return formResponseValues;
};
