import { css, styled } from 'styled-components';

import PropTypes from 'prop-types';

import partners from '../../partners';
import { useBrand } from '../../BrandContext';
import { withText } from '../../text/textStore';

import TitleSection from '../TitleSection';
import HeadingLevelOne from '../HeadingLevelOne';
import Hero from '../Hero';

// on <= iOS 15 overflow-x: clip; is not supported. Duplicate property used as fallback
const HeroWrapper = styled('div')`
    ${({ theme }) => css`
        overflow-y: visible;
        /* stylelint-disable declaration-block-no-duplicate-properties */
        overflow-x: hidden;
        overflow-x: clip;
        background-color: ${theme.colors.capitalOne.blue750};
        ${theme.components.HeroWrapper}
    `}
`;
HeroWrapper.displayName = 'HeroWrapper';

export const FormTitleSection = styled(TitleSection)`
    ${({ theme }) => css`
        padding-top: ${theme.space.sm};
        padding-bottom: ${theme.space.md};
        margin: auto;
        max-width: 92rem;

        /* required to override TitleSection styles at this breakpoint */
        ${theme.breakpoints.mq.tabletMin} {
            padding-top: ${theme.space.sm};
            padding-bottom: ${theme.space.md};
        }
        ${theme.components.FormTitleSection}
    `}
`;
FormTitleSection.displayName = 'FormTitleSection';

export const StandardTitleText = styled.span`
    ${({ theme }) => css`
        color: ${theme.colors.global.white};
        ${theme.components.StandardTitleText}
    `}
`;
StandardTitleText.displayName = 'StandardTitleText';

export const HeaderText = styled.span`
    ${({ theme }) => css`
        color: ${theme.colors.global.white};
        font-size: ${theme.fontSizes.textMediumDesktop};
        ${theme.breakpoints.mq.tabletMin} {
            font-size: ${theme.fontSizes.textLargeDesktop};
        }
        ${theme.components.HeaderText}
    `}
`;
HeaderText.displayName = 'HeaderText';

export const HeaderSection = styled.div`
    ${({ theme }) => css`
        padding-bottom: ${theme.space.sm};
        margin: auto;
        max-width: 39rem;
        text-align: center;
        ${theme.breakpoints.mq.tabletMin} {
            max-width: 75rem;
            padding-bottom: ${theme.space.md};
        }
        ${theme.components.HeaderSection}
    `}
`;
HeaderSection.displayName = 'HeaderSection';

export const StyledPageTitle = styled(HeadingLevelOne)`
    font-size: 6rem;
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMax} {
            font-size: 5rem;
        }
        ${theme.breakpoints.mq.mobileMax} {
            font-size: 3.2rem;
        }
    `}
`;
StyledPageTitle.displayName = 'StyledPageTitle';

export const EmphasizedTitleText = styled.span`
    ${({ theme }) => css`
        color: ${theme.colors.capitalOne.teal450};
        ${theme.components.EmphasizedTitleText}
    `}
`;
EmphasizedTitleText.displayName = 'EmphasizedTitleText';

const QuotationHeroSection = ({ Text: text, isPartnerAggsEdit }) => {
    const brand = useBrand();
    return (
        <HeroWrapper>
            {isPartnerAggsEdit ? (
                <>
                    <FormTitleSection>
                        <HeadingLevelOne px={['sm', 'xl', 'md']}>
                            <StandardTitleText data-qa-id="page-title-2">
                                {text({ path: 'EditTitle' })}
                            </StandardTitleText>
                        </HeadingLevelOne>
                    </FormTitleSection>
                    <HeaderSection>
                        <HeaderText data-qa-id="page-header">{text({ path: 'EditHeader' })}</HeaderText>
                    </HeaderSection>
                </>
            ) : (
                <>
                    <>
                        <FormTitleSection>
                            {brand === partners.VERY ? (
                                <StyledPageTitle px={['sm', 'xl', 'md']}>
                                    <EmphasizedTitleText data-qa-id="page-title-1">
                                        {text({ path: 'FormTitle.part1' })}
                                        {text({ path: 'FormTitle.part2' })}
                                    </EmphasizedTitleText>
                                </StyledPageTitle>
                            ) : (
                                <HeadingLevelOne px={['sm', 'xl', 'md']}>
                                    <EmphasizedTitleText data-qa-id="page-title-1">
                                        {text({ path: 'FormTitle.part1' })}
                                    </EmphasizedTitleText>
                                    {text({ path: 'FormTitle.part2' }) && (
                                        <StandardTitleText data-qa-id="page-title-2">
                                            {text({ path: 'FormTitle.part2' })}
                                        </StandardTitleText>
                                    )}
                                </HeadingLevelOne>
                            )}
                        </FormTitleSection>
                        <Hero brand={brand} />
                    </>
                </>
            )}
        </HeroWrapper>
    );
};
QuotationHeroSection.propTypes = {
    Text: PropTypes.func.isRequired,
    isPartnerAggsEdit: PropTypes.bool,
    urlVendorCode: PropTypes.string
};

QuotationHeroSection.defaultProps = {
    isPartnerAggsEdit: false,
    urlVendorCode: undefined
};

const WrappedQuotationHeroSection = withText('QuotationForm')(QuotationHeroSection);

WrappedQuotationHeroSection.displayName = 'QuotationHeroSection';

export default WrappedQuotationHeroSection;
