import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const SessionContext = createContext({
    journey: null,
    sessionId: null
});

export const SessionProvider = ({ children, journey, sessionId }) => {
    return (
        <SessionContext.Provider
            value={{
                journey,
                sessionId
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

SessionProvider.propTypes = {
    children: PropTypes.node.isRequired,
    journey: PropTypes.string,
    sessionId: PropTypes.string
};

SessionProvider.defaultProps = {
    journey: undefined,
    sessionId: undefined
};

export const useJourney = () => {
    const { journey } = useContext(SessionContext);
    return journey;
};

export const useSessionId = () => {
    const { sessionId } = useContext(SessionContext);
    return sessionId;
};
