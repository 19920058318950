import { capitalOneTheme } from '@cof/plastic-components';
import merge from 'lodash.merge';
import { css } from 'styled-components';

/**
 * Return feature styles based on a feature and mapping
 */
export const getFeatureStyles = (features, featureMap) =>
    features && typeof features === 'object'
        ? Object.keys(features).map((feature) => (features[feature] ? featureMap[feature] : undefined))
        : null;

// export necessary plastic component styles
const { radioGroup: pRadioGroup } = capitalOneTheme.components;

export const VisuallyHidden = css`
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
`;

export const Field = css`
    border: none;
    padding: 0;
    margin: 0;
`;

/**
 * PLASTIC COMPONENT UPDATES BELOW HERE
 */

export const radioGroup = merge({}, pRadioGroup, {
    radioButton: {
        horizontal: () => css`
            ${pRadioGroup.radioButton.horizontal}
            height: 2.4rem;
            width: 2.4rem;
        `
    }
});
