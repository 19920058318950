import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import template from '@cof/acq-text-ukweb/lib/plugins/transformers/template';
import merge from 'lodash.merge';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';

import { useBrand } from './BrandContext';
import partners from './partners';
import defaultGlobalStyles from './styles/capitalOne/globalStyles';
import defaultTheme from './styles/capitalOne/index';
import defaultContent from './text/brands/capitalOne/content';
import { TextStore } from './text/textStore';
import jsx from './text/transformers/jsx';
import useExperiment from './utilities/useExperiment';

const ThemeProvider = ({ children }) => {
    const brand = useBrand();
    const [theme, setTheme] = useState({
        globalTheme: undefined,
        GlobalStyles: undefined,
        content: undefined,
        themeLoaded: false
    });
    const { GlobalStyles, themeLoaded } = theme;

    const { isTest } = useExperiment(2); // checks s-param to see if 2nd char is "a" | "b"

    useEffect(() => {
        const loadTheme = async () => {
            try {
                const { default: dynamicTheme } = await import(`./styles/${brand}/theme/index.js`);
                const { default: dynamicGlobalStyles } = await import(`./styles/${brand}/global/index.js`);
                const { default: partnerContent } = await import(`./text/brands/${brand}/content.js`);

                let experimentContent = {};

                if (brand === partners.ASOS && isTest) {
                    const { default: instantIssuanceContent } = await import(
                        `./text/brands/${brand}/instant-issuance.js`
                    );
                    experimentContent = instantIssuanceContent;
                }

                const textContent = merge({}, defaultContent, partnerContent, experimentContent);

                setTheme({
                    globalTheme: dynamicTheme,
                    GlobalStyles: dynamicGlobalStyles,
                    content: textContent,
                    themeLoaded: true
                });
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e?.message);
                setTheme({
                    globalTheme: defaultTheme,
                    GlobalStyles: defaultGlobalStyles,
                    content: defaultContent,
                    themeLoaded: true
                });
            }
        };
        if (brand === partners.DEFAULT) {
            setTheme({
                globalTheme: defaultTheme,
                GlobalStyles: defaultGlobalStyles,
                content: defaultContent,
                themeLoaded: true
            });
        } else {
            loadTheme();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {themeLoaded && (
                <>
                    <BaseThemeProvider theme={theme.globalTheme}>
                        <GlobalStyles />
                        <TextStore
                            transformers={{
                                template,
                                jsx
                            }}
                            value={theme.content}
                        >
                            {children}
                        </TextStore>
                    </BaseThemeProvider>
                </>
            )}
        </>
    );
};

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default ThemeProvider;
