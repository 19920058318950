import config from '@cof/ukweb-config/lib/clientConfig';

// eslint-disable-next-line import/prefer-default-export
export const reportAppDError = () => {
    const appDConfig = config.get('appDynamics');
    const appDCookie = window.ADRUM;

    if (appDConfig.isEnabled && appDCookie) {
        const error = new window.ADRUM.events.Error();
        error.msg('quotations-web-ui:technical-error');
        window.ADRUM.report(error);
    }
};
