import useQueryParams from '../useQueryParams';

const defaultOptions = {
    control: 'A',
    test: 'B'
};

const useExperiment = (idx, options = defaultOptions) => {
    const { sParameter } = useQueryParams();

    if (typeof idx === 'undefined') {
        throw new Error('you must provide the index for the s-param character you want to use.');
    }

    if (!options.control || !options.test) {
        throw new Error('you must provide a valid character for both the control and test variant.');
    }

    const character = sParameter?.[idx - 1];
    const isControl = character === options.control;
    const isTest = character === options.test;

    return {
        character,
        isControl,
        isTest
    };
};

export default useExperiment;
