/* eslint complexity: ["error", 8] */
import { useBrand } from '@cof/plastic-components';
import isMobile from 'is-mobile';

import partners from '../../partners';
import useExperiment from '../useExperiment';
import useQueryParams from '../useQueryParams';

const setSearchParameter = (value) => {
    const URLObj = new URL(window.location);
    URLObj.searchParams.set('s', value);
    window.history.replaceState(null, '', URLObj.href);
};

const replaceChars = (original, idx, chars) => {
    return `${original.substring(0, idx)}${chars}${original.substring(idx + chars.length)}`;
};

const options = {
    control: 'A',
    test: 'B'
};

const TrafficSplitProvider = ({ children }) => {
    const brand = useBrand();
    const isAsos = brand === partners.ASOS;

    const { sParameter, oemc } = useQueryParams();
    const { isTest: isAlreadyTest, isControl: isAlreadyControl } = useExperiment(2, options);
    const vendorCode = oemc ?? 'ASO';
    let latestParameter = sParameter ?? `xxxxxxxx${vendorCode}xxxxxBANxxxxxx`;
    // persist o param as vendor code if present

    const isMobileDevice = isMobile({ tablet: true, featureDetect: false });

    if (!isMobileDevice || !isAsos) {
        // user is not on mobile or is not an asos user so not eligible for this test
        return children;
    }

    if (isAlreadyTest || isAlreadyControl) {
        // user is eligible but already in the test or control pool
        return children;
    }

    const isTest = Math.random() <= 0.5;
    latestParameter = replaceChars(latestParameter, 1, !isTest ? options.control : options.test);
    setSearchParameter(latestParameter);

    return children;
};

export default TrafficSplitProvider;
