import PropTypes from 'prop-types';

import Bowser from 'bowser';

const BrowserCheck = ({ children }) => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
        'internet explorer': '>11'
    });
    if (isValidBrowser === false) {
        // TODO: Modify as part of https://jira.kdc.capitalone.com/browse/UKFNPM-52963
        window.location.assign('https://www.capitalone.co.uk/support/browser-block.jsf');
        return null;
    }
    return children;
};

BrowserCheck.propTypes = {
    children: PropTypes.node.isRequired
};

export default BrowserCheck;
