import { BrandProvider } from './BrandContext';
import BrowserCheck from './BrowserCheck';
import DocumentHead from './components/DocumentHead';
import ErrorBlockedRouter from './components/ErrorBlockedRouter';
import ErrorBoundary from './components/ErrorBoundary';
import FormDataContextProvider from './components/FormDataContext/FormDataContext';
import LandmarkRefsProvider from './components/LandmarkRefsProvider';
import Quotation from './components/Quotation';
import SkipLink from './components/SkipLink';
import TransformedCookieBanner from './components/TransformedCookieBanner';
import ExperimentProvider from './experiments/ExperimentContext';
import TechnicalError from './pages/TechnicalError';
import ThemeProvider from './ThemeProvider';
import { AggregatorConsumerNameProvider } from './utilities/aggregatorConsumerNameProvider';
import { BRANDS } from './utilities/constants';
import onCookieManagementEvent from './utilities/onCookieManagementEvent';
import TrafficSplitProvider from './utilities/trafficSplitProvider';

function App() {
    return (
        <BrowserCheck>
            <BrandProvider>
                {/* This is specific to the ASOS Instant Issuance Test  */}
                <TrafficSplitProvider>
                    <ThemeProvider>
                        <AggregatorConsumerNameProvider>
                            <ErrorBoundary renderOnError={<TechnicalError />}>
                                <LandmarkRefsProvider>
                                    <DocumentHead />
                                    <SkipLink />
                                    <ErrorBlockedRouter>
                                        <ExperimentProvider>
                                            <FormDataContextProvider>
                                                <Quotation />
                                                <TransformedCookieBanner
                                                    allowList={BRANDS}
                                                    id="cookie-banner"
                                                    onCookieManagementEvent={onCookieManagementEvent}
                                                />
                                            </FormDataContextProvider>
                                        </ExperimentProvider>
                                    </ErrorBlockedRouter>
                                </LandmarkRefsProvider>
                            </ErrorBoundary>
                        </AggregatorConsumerNameProvider>
                    </ThemeProvider>
                </TrafficSplitProvider>
            </BrandProvider>
        </BrowserCheck>
    );
}

export default App;
