import PropTypes from 'prop-types';

import { Box, Heading, Link, PageRow, Paragraph } from '@cof/plastic-components';

import { withText } from '../../../../text/textStore';

const GetToKnow = ({ Text, Text: text, JsxText }) => (
    <PageRow>
        <Box data-qa-id="get-to-know">
            <Heading data-qa-id="heading" level="2">
                <Text path="GetToKnow.Heading" />
            </Heading>
            <Paragraph data-qa-id="body1">
                <Text path="GetToKnow.Body1" />
            </Paragraph>

            <Paragraph data-qa-id="body2">
                <JsxText
                    path="GetToKnow.Body2.Copy"
                    params={{
                        EQUIFAX: (
                            <Link external href={text({ path: 'GetToKnow.Body2.Equifax.Target' })}>
                                <Text path="GetToKnow.Body2.Equifax.Text" />
                            </Link>
                        ),
                        TRANSUNION: (
                            <Link external href={text({ path: 'GetToKnow.Body2.Transunion.Target' })}>
                                <Text path="GetToKnow.Body2.Transunion.Text" />
                            </Link>
                        ),
                        EXPERIAN: (
                            <Link external href={text({ path: 'GetToKnow.Body2.Experian.Target' })}>
                                <Text path="GetToKnow.Body2.Experian.Text" />
                            </Link>
                        )
                    }}
                />
            </Paragraph>
            <Paragraph data-qa-id="body3">
                <JsxText
                    path="GetToKnow.Body3.Copy"
                    params={{
                        IMPROVE_SCORE_LINK: (
                            <Link href={text({ path: 'GetToKnow.Body3.ImproveScore.Target' })}>
                                <Text path="GetToKnow.Body3.ImproveScore.Text" />
                            </Link>
                        )
                    }}
                />
            </Paragraph>
            <Paragraph data-qa-id="body4">
                <Link href={text({ path: 'GetToKnow.Body4.Target' })}>
                    <Text path="GetToKnow.Body4.Copy" />
                </Link>
            </Paragraph>
        </Box>
    </PageRow>
);

GetToKnow.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const WrappedGetToKnow = withText('ScoreDecline')(GetToKnow);
WrappedGetToKnow.displayName = 'GetToKnow';

export default WrappedGetToKnow;
