import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Flow,
    FlowPoint,
    Heading,
    Link,
    Paragraph,
    THEME_CONSTANTS,
    useBrand
} from '@cof/plastic-components';
import { styled } from 'styled-components';
import { isAggregatorConsumer } from '../../utilities/utils';

import BodySection from '../../components/BodySection';
import Header from '../../components/Header';
import Main from '../../components/Main';
import TitleSection, { TitleSectionHeading } from '../../components/TitleSection';
import { withText } from '../../text/textStore';
import useBackground from '../../utilities/useBackground';
import MoneyHelperBanner from '../../components/MoneyHelperBanner';
import partners from '../../partners';
import { useFormData } from '../../components/FormDataContext';

const { COLORS } = THEME_CONSTANTS;

const AffordabilityDeclineLearnWrapper = styled(Box)`
    ${({ theme }) => theme.components.AffordabilityDeclineLearnWrapper}
`;

const AffordabilityDecline = ({ Text, Text: text, JsxText }) => {
    const { formData } = useFormData();
    const consumerName = formData?.consumerName;
    const background = useBackground('affordabilityDecline');
    const brand = useBrand();
    const isAgg = isAggregatorConsumer(consumerName);
    return (
        <>
            <Header />
            <Main bg={background} data-qa-id="affordability-decline-page">
                <TitleSection>
                    <TitleSectionHeading>
                        <Text path="Title" />
                    </TitleSectionHeading>
                </TitleSection>
                <BodySection data-qa-id="credit-file">
                    <Paragraph data-qa-id="body1" whiteSpace="pre-wrap">
                        <Text path="Body1" />
                    </Paragraph>
                    {brand === partners.DEFAULT && !isAgg && <MoneyHelperBanner />}
                    <Heading level="2" data-qa-id="heading">
                        <Text path="CreditFile.Heading" />
                    </Heading>
                    <Paragraph data-qa-id="body2" whiteSpace="pre-wrap">
                        <Text path="CreditFile.Body" />
                    </Paragraph>
                    <Flow
                        data-qa-id="flow"
                        my="lg"
                        variant={background === COLORS.global.white ? 'light' : 'dark'}
                        px={0}
                    >
                        {[1, 2, 3].map((point) => (
                            <FlowPoint key={point} data-qa-id="flow-point">
                                {text({ path: `Points.Point${point}` })}
                            </FlowPoint>
                        ))}
                    </Flow>
                    <Paragraph data-qa-id="body3">
                        <JsxText
                            path="Body2.Copy"
                            params={{
                                EQUIFAX: (
                                    <Link external href={text({ path: 'Body2.Equifax.Target' })}>
                                        <Text path="Body2.Equifax.Text" />
                                    </Link>
                                ),
                                TRANSUNION: (
                                    <Link external href={text({ path: 'Body2.Transunion.Target' })}>
                                        <Text path="Body2.Transunion.Text" />
                                    </Link>
                                ),
                                EXPERIAN: (
                                    <Link external href={text({ path: 'Body2.Experian.Target' })}>
                                        <Text path="Body2.Experian.Text" />
                                    </Link>
                                )
                            }}
                        />
                    </Paragraph>
                </BodySection>
                <AffordabilityDeclineLearnWrapper bg="global.grey50" data-qa-id="learn-about-credit">
                    <BodySection textAlign="center" data-qa-id="learn-about-credit-inner">
                        <Heading level="2">
                            <Text path="LearnAboutCredit.Heading" />
                        </Heading>
                        <Paragraph>
                            <Text path="LearnAboutCredit.Body" />
                        </Paragraph>
                        <Button
                            as="a"
                            mt="md"
                            href={text({ path: 'LearnAboutCredit.Button.Target' })}
                            data-qa-id="learn-about-credit-button"
                        >
                            <Text path="LearnAboutCredit.Button.Text" />
                        </Button>
                    </BodySection>
                </AffordabilityDeclineLearnWrapper>
                <BodySection textAlign="center" data-qa-id="get-in-touch">
                    <Paragraph>
                        <JsxText
                            path="Body3.Copy"
                            params={{
                                GET_IN_TOUCH_LINK: (
                                    <Link href={text({ path: 'Body3.Link.Target' })}>
                                        <Text path="Body3.Link.Text" />
                                    </Link>
                                )
                            }}
                        />
                    </Paragraph>
                </BodySection>
            </Main>
        </>
    );
};

AffordabilityDecline.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const WrappedAffordabilityDecline = withText('AffordabilityDecline')(AffordabilityDecline);

WrappedAffordabilityDecline.displayName = 'AffordabilityDecline';

export default WrappedAffordabilityDecline;
