import PropTypes from 'prop-types';

import { Box, Flex } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

import { withText } from '../../text/textStore';
import { useAggregatorConsumerName } from '../../utilities/aggregatorConsumerNameProvider';
import { hasAggregatorLogo } from '../../utilities/utils';
import Logo from '../Logo';
import aggregatorsLogoMap from '../Logo/AggregatorsLogo/aggregatorsLogoMap';

export const StyledHeader = styled.header`
    ${({ theme, $isAggregatorWithLogo }) => css`
        background-color: ${$isAggregatorWithLogo ? theme.colors.global.white : theme.colors.capitalOne.blue750};
        border: ${$isAggregatorWithLogo ? theme.colors.global.white : theme.colors.capitalOne.blue750};
        margin: 0;
        padding: ${theme.space.sm} 0;
        text-align: center;
        font-size: 0;
        position: relative;
        z-index: 1;

        ${theme.breakpoints.mq.tabletMin} {
            padding: ${theme.space.lg} 0;
            padding-left: ${theme.space.lg};
            text-align: left;
        }

        ${theme.components.StyledHeader}
    `}
`;

const Header = ({ Text: text, mainPage }) => {
    const [aggregatorConsumerName] = useAggregatorConsumerName();
    const isAggregatorWithLogo = hasAggregatorLogo(aggregatorConsumerName);
    const variant = isAggregatorWithLogo ? 'dark' : 'light';

    return (
        <StyledHeader $isAggregatorWithLogo={isAggregatorWithLogo} data-qa-id="header" $mainPage={mainPage}>
            <Flex width="100%" height="100%" justifyContent={['center', null, 'flex-start']} alignItems="center">
                <a data-qa-id="header-link" href={text({ path: 'Link' })} aria-label={text({ path: 'AriaLabel' })}>
                    <Logo variant={variant} />
                </a>
                {isAggregatorWithLogo && (
                    <Box
                        borderLeft="1px solid black"
                        marginLeft="10px"
                        paddingLeft="10px"
                        data-qa-id="aggregator-logo-wrapper"
                    >
                        {aggregatorsLogoMap[aggregatorConsumerName]}
                    </Box>
                )}
            </Flex>
        </StyledHeader>
    );
};

Header.propTypes = {
    Text: PropTypes.func.isRequired,
    mainPage: PropTypes.bool
};

Header.defaultProps = {
    mainPage: false
};

const WrappedHeader = withText('Header')(Header);

WrappedHeader.displayName = 'Header';

export default WrappedHeader;
