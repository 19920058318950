/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';

import {
    defaultSeasonalBrandConfig,
    BrandedSeasonalLogo,
    PostOfficeLogoCore,
    ThinkMoneyLogoPrimaryDark,
    useBrand
} from '@cof/plastic-components';

import { css, styled } from 'styled-components';
import cssSystem from '@styled-system/css';

import partners from '../../partners';

const { THINKMONEY, POSTOFFICE, ASOS } = partners;

const brandConfig = {
    ...defaultSeasonalBrandConfig,
    [THINKMONEY]: ThinkMoneyLogoPrimaryDark,
    [POSTOFFICE]: PostOfficeLogoCore
};

const StyledBrandedSeasonalLogo = styled(BrandedSeasonalLogo)`
    ${({ brand }) =>
        brand === 'capitalOne' &&
        css`
            ${cssSystem({ width: ['8.3rem', null, '14rem'] })}
        `}
`;

brandConfig[ASOS] = brandConfig.ASOS;

// pass null for the allowList as this will cause it to pick up the allowList from the top level brand provider
const Logo = ({ variant, ...otherProps }) => {
    const brand = useBrand();
    return (
        <StyledBrandedSeasonalLogo
            data-qa-id="branded-logo"
            brandConfig={brandConfig}
            allowList={null}
            variant={variant}
            mx={['auto', null, 0]}
            brand={brand}
            {...otherProps}
        />
    );
};

Logo.propTypes = {
    variant: PropTypes.string
};

Logo.defaultProps = {
    variant: undefined
};

export default Logo;
