/* eslint-disable max-len */
const ExternalLinks = {
    CAP_ONE: 'https://www.capitalone.co.uk',
    LEGAL: 'https://www.capitalone.co.uk/compliance/legal-information',
    COOKIES: 'https://www.capitalone.co.uk/compliance/cookie-policy',
    ACCESSIBILITY: 'https://www.capitalone.co.uk/support/accessibility-and-browser-support',
    CONTACT: 'https://www.capitalone.co.uk/support/contact-us',
    PRIVACY_POLICY: 'https://www.capitalone.co.uk/compliance/privacy-policy',
    CREDITWISE_REGISTRATION: 'https://creditwise.capitalone.co.uk/registration?vc=DEC',
    EQUIFAX: 'https://www.equifax.co.uk/',
    TRANSUNION: 'https://www.transunion.co.uk/crain',
    EXPERIAN: 'https://www.experian.co.uk/',
    CREDIT_MADE_CLEARER: 'https://www.capitalone.co.uk/creditmadeclearer',
    QUICKCHECK: 'https://quickcheck.capitalone.co.uk',
    MONEY_HELPER_GUIDE:
        'https://www.moneyhelper.org.uk/en/everyday-money/credit/what-to-do-if-you-have-been-refused-a-loan-or-credit-card'
};

const content = {
    AlreadyAppliedDecline: {
        Title: "Hey {{NAME}}, you've already applied for one of our cards",
        Paragraph1:
            "Thanks for applying. It looks like you've already got a previous application being processed with us, or one of our partner credit cards.",
        Paragraph2: "You can find out what's happening with your existing application by {{CONTACT}}",
        ContactUs: 'contacting us.',
        ContactUsLink: ExternalLinks.CONTACT,
        HomePage: 'Homepage',
        HomePageLink: ExternalLinks.CAP_ONE
    },
    TechnicalError: {
        Title: 'Oops, something went wrong',
        Body: 'You can try QuickCheck again later or go to our homepage to find out more about Capital One and our credit cards.'
    },
    TimedOut: {
        Title: 'Your session has timed out',
        Paragraph1: 'Sorry about that. QuickCheck was inactive for a while, so we closed it to keep your details safe.',
        Paragraph2: 'Please start QuickCheck again to see if you’re eligible for a credit card.',
        Button: {
            Text: 'Start QuickCheck'
        }
    },
    Spinner: {
        Title: "We're checking your details"
    },
    MoneyHelperBanner: {
        Heading: 'Improve your financial situation',
        Body1: `If you've been declined for a card, it's important to understand why. Use MoneyHelper's guide to see if there's anywhere you could be making changes.`,
        Body2: 'Go to {{MONEY_HELPER_GUIDE}}',
        LinkText: "'Money Helper Guide'",
        GuideLink: ExternalLinks.MONEY_HELPER_GUIDE
    },
    ScoreDecline: {
        Title: "We can't give you a card right now",
        Subtitle: 'Your credit score is too low at the moment.',
        HowDidWeDecide: {
            Heading: 'How did we decide this?',
            Body: {
                Copy: 'We looked at information from one or more credit reference agencies. For example, we may have used data from  {{EQUIFAX}} , {{TRANSUNION}} and {{EXPERIAN}} to decide if we can lend to you.',
                Equifax: {
                    Text: 'Equifax',
                    Target: ExternalLinks.EQUIFAX
                },
                Transunion: {
                    Text: 'Transunion',
                    Target: ExternalLinks.TRANSUNION
                },
                Experian: {
                    Text: 'Experian',
                    Target: ExternalLinks.EXPERIAN
                }
            }
        },
        WhatCanYouDo: {
            Heading: 'What can you do now?',
            List: {
                Item1: 'Check what information the credit reference agencies have about you. You can contact them directly.',
                Item2: 'Learn how to improve your score. Try a free credit score tool like Capital One’s {{CREDITWISE}}.'
            },
            CreditWise: {
                Text: 'CreditWise',
                Target: ExternalLinks.CREDITWISE_REGISTRATION
            }
        },
        CreditScore: {
            Heading: 'See your credit score for free, right now',
            Body: 'We need just a few details to get you started.',
            Button: {
                Text: 'Go to CreditWise',
                Target: ExternalLinks.CREDITWISE_REGISTRATION
            }
        },
        Points: {
            Point1: {
                Heading: 'Your free credit score',
                Body: '100% free access, forever. Sign in as often as you like.'
            },
            Point2: {
                Heading: 'See your score in minutes',
                Body: 'Quick and easy sign-up. It won’t impact your credit score.'
            },
            Point3: {
                Heading: 'Understand your score',
                Body: "See what's going well and how to improve your score."
            }
        },
        GetToKnow: {
            Heading: 'Get to know your credit score and report',
            Body1: "Behind your credit score and report is a bigger story about the way you manage your money. By understanding what goes into making your score, you'll see how you can improve it and get better credit in the future.",
            Body2: {
                Copy: "We may use data from {{EQUIFAX}}, {{TRANSUNION}} and {{EXPERIAN}} to decide if we can lend to you, so it's important this information is correct. You can sign up with them to check your details (some may charge a fee).",
                Equifax: {
                    Text: 'Equifax',
                    Target: ExternalLinks.EQUIFAX
                },
                Transunion: {
                    Text: 'Transunion',
                    Target: ExternalLinks.TRANSUNION
                },
                Experian: {
                    Text: 'Experian',
                    Target: ExternalLinks.EXPERIAN
                }
            },
            Body3: {
                Copy: 'If your details at the agencies are correct, you can learn how to {{IMPROVE_SCORE_LINK}} right now, which is the key to being approved for credit in the future.',
                ImproveScore: {
                    Text: 'start improving your credit score',
                    Target: ExternalLinks.CREDIT_MADE_CLEARER
                }
            },
            Body4: {
                Copy: 'Get in touch with us if you have any additional questions.',
                Target: ExternalLinks.CONTACT
            }
        }
    },
    CreditBureauDecline: {
        Title: 'Your credit rating is too low',
        Subtitle: "A low credit rating means we can't give you a credit card just yet.",
        Body1: "Fortunately, there are things you can do to improve your rating and make it easier to get credit in the future. Here's what you can do next:",
        CreditFile: {
            Heading: 'Check your credit file',
            Body: "Your rating is based on information the credit reference agencies give us about how well you've looked after credit in the past, among other details.\n\nYou can see the information the credit reference agencies share with us by contacting them. If it is wrong, let them know so they can fix it."
        },
        Points: {
            Point1: 'Contact the credit reference agencies',
            Point2: 'Check the details they have about you',
            Point3: "Update anything that's incorrect",
            Point4: 'Return to QuickCheck at a later date'
        },
        Body2: {
            Copy: 'We use {{EQUIFAX}}, {{TRANSUNION}} and {{EXPERIAN}} to compare your details so by signing up with them you can check your details are correct (some may charge a small fee).',
            Equifax: {
                Text: 'Equifax',
                Target: ExternalLinks.EQUIFAX
            },
            Transunion: {
                Text: 'Transunion',
                Target: ExternalLinks.TRANSUNION
            },
            Experian: {
                Text: 'Experian',
                Target: ExternalLinks.EXPERIAN
            }
        },
        CreditRating: {
            Heading: 'Improve your credit rating',
            Body: 'Your credit file is correct? Great! In that case, you need to learn how to start building and improving your credit rating. Improving your rating is key to being approved in the future. {{CREDIT_MADE_CLEARER_LINK}} to learn some simple tips on how you can do this.',
            CreditMadeClearer: {
                Text: 'Visit Credit Made Clearer',
                Target: ExternalLinks.CREDIT_MADE_CLEARER
            },
            Button: {
                Text: 'Improve your credit rating tips',
                Target: ExternalLinks.CREDIT_MADE_CLEARER
            }
        },
        Body3: {
            Copy: '{{GET_IN_TOUCH_LINK}} with us if you have any additional questions.',
            Link: {
                Text: 'Get in touch',
                Target: ExternalLinks.CONTACT
            }
        }
    },
    InformationMismatch: {
        Title: "We can't find your information",
        Playback: {
            Preamble:
                "We can't lend to you because the information you have given doesn't match the information with the credit reference agencies. Read the following and this could change.",
            Heading: 'Are your details correct?',
            FullNameHeading: 'Full Name',
            DateOfBirthHeading: 'Date of Birth',
            YourWorkHeading: 'Your work',
            AddressHeading: 'Address',
            YearsAtAddressHeading: 'Years at address',
            Body: 'If the information is incorrect, simply change your details in QuickCheck.',
            CTA: {
                Text: 'Change your details',
                Target: ExternalLinks.QUICKCHECK
            }
        },
        GetInTouch: {
            Body: '{{GET_IN_TOUCH_LINK}} with us if you have any additional questions.',
            Link: {
                Text: 'Get in touch',
                Target: ExternalLinks.CONTACT
            }
        }
    },
    AffordabilityDecline: {
        Title: "You didn't pass our affordability checks",
        Body1: "As a responsible lender, it's important we don't put customers in a position of having more credit than they may be able to afford to repay. Here's what you can do next:",
        CreditFile: {
            Heading: 'Check your credit file',
            Body: 'We use information the credit reference agencies give us about you to help us check of you can afford to have a credit card with us.\n\nYou can see the information the credit reference agencies share with us by contacting them. If it is wrong, let them know so they can fix it.'
        },
        Points: {
            Point1: 'Check the details they have about you',
            Point2: 'Make any corrections if needed',
            Point3: 'Return to QuickCheck at a later date'
        },
        Body2: {
            Copy: 'We use {{EQUIFAX}}, {{TRANSUNION}} and {{EXPERIAN}} to compare your details so by signing up with them you can check your details are correct (some may charge a small fee).',
            Equifax: {
                Text: 'Equifax',
                Target: ExternalLinks.EQUIFAX
            },
            Transunion: {
                Text: 'Transunion',
                Target: ExternalLinks.TRANSUNION
            },
            Experian: {
                Text: 'Experian',
                Target: ExternalLinks.EXPERIAN
            }
        },
        LearnAboutCredit: {
            Heading: 'Managing your finances can be tough',
            Body: "That's why we are committed to helping you through a variety of credit education and other tools.",
            Button: {
                Text: 'Learn about credit',
                Target: ExternalLinks.CREDIT_MADE_CLEARER
            }
        },
        Body3: {
            Copy: '{{GET_IN_TOUCH_LINK}} with us if you have any additional questions.',
            Link: {
                Text: 'Get in touch',
                Target: ExternalLinks.CONTACT
            }
        }
    },
    CheckYourCreditFile: {
        Heading: 'Check your credit file',
        Body1: "Okay, so you've checked your details and they're correct. Here's what you need to do next:",
        Points: {
            Point1: 'Contact the credit reference agencies',
            Point2: 'Check the details they have about you',
            Point3: "Update anything that's incorrect",
            Point4: 'Come back to QuickCheck'
        },
        Body2: {
            Text: 'We use {{EQUIFAX}}, {{TRANSUNION}} and {{EXPERIAN}} to compare your details so by signing up with them you can check your details are correct (some may charge a small fee).',
            Equifax: {
                Text: 'Equifax',
                Target: ExternalLinks.EQUIFAX
            },
            Transunion: {
                Text: 'Transunion',
                Target: ExternalLinks.TRANSUNION
            },
            Experian: {
                Text: 'Experian',
                Target: ExternalLinks.EXPERIAN
            }
        }
    },
    DatePicker: {
        Day: 'Day',
        Month: 'Month',
        Year: 'Year'
    },
    DocumentHead: {
        Title: 'Which credit card is right for you? - Capital One',
        Description: "Fill in your details to check if you'll be accepted. Won't affect your credit rating."
    },
    Header: {
        Link: ExternalLinks.CAP_ONE,
        AriaLabel: 'Capital One home page'
    },
    Hero: {
        feature1: 'Response in 60 seconds',
        feature2: "Won't affect your credit rating",
        feature3: '100% certainty before you apply',
        FeatureOne: {
            Icon: 'Stopwatch',
            Header: 'Quick and easy',
            Text: 'QuickCheck is easy to use and provides a fast, no risk answer to the question, "Will I be approved for a credit card?" Try QuickCheck to check your eligibility now.'
        },
        FeatureTwo: {
            Icon: 'ProtectionFinance',
            Header: "We won't affect your credit rating",
            Text1: "We think it's responsible to tell you whether you will be approved for a card before you apply. By working with credit reference agencies we do a pre-application check of your credit rating using QuickCheck.",
            Text2: "You'll be able to see the search on your file, but it won't show up to other organisations. This means there is no impact to your credit rating."
        },
        FeatureThree: {
            Icon: 'ThumbsUp',
            Header: 'Apply with confidence',
            Text1: 'If you decide to apply for a card you can apply with confidence knowing you will be accepted, at which point we do a visible update to the credit reference agencies to let them know you asked us for credit.',
            Text2: "We stand behind our answer and confirm that we've carried out all the appropriate credit checks we need. However, there is a small chance that we may be unable to open your account if we identify certain information on fraud prevention databases or if we are not able to verify your identity.",
            Text3: '',
            Text4: ''
        }
    },
    NoImpactModal: {
        FeatureTwo: {
            Header: "We won't affect your credit rating",
            Text1: "We think it's responsible to tell you whether you will be approved for a card before you apply. By working with credit reference agencies we do a pre-application check of your credit rating using QuickCheck.",
            Text2: "You'll be able to see the search on your file, but it won't show up to other organisations. This means there is no impact to your credit rating."
        }
    },
    QuotationForm: {
        FormTitle: {
            part1: 'Fill in your details ',
            part2: "to check if you'll be accepted"
        },
        EditTitle: 'Edit your details',
        EditHeader:
            'Make changes with no impact on your credit score, then get a fresh yes or no based on your new information.',
        Submission: {
            InProgress: 'Submitting...'
        },
        Wizard: {
            NextButton: {
                Default: 'Continue',
                Start: 'Get started',
                StepNumber: 'Continue to step {{nextStageNumber}}',
                StageTitle: `Continue to '{{stageTitle}}'`,
                PrePop: 'Go to next answer'
            },
            skipEditButton: 'I’ve made my changes',
            PreviousButton: 'Back',
            SubmitButton: 'QuickCheck now'
        },
        NoHarmFeature: 'No impact to your credit score',
        PhoneNumber: {
            ButtonText: 'Add home number (optional)',
            LinkText: 'Add home phone number (optional)'
        },
        Address: {
            LookUp: {
                Label: {
                    PostCode: 'Postcode',
                    PostCodeCurrentAddress: "What's your postcode?",
                    PostCodePreviousAddress: 'What was your postcode before this?',
                    HouseNumber: 'House/flat name or number (optional)'
                },
                Errors: {
                    PostCode: {
                        Required: 'Postcode is required',
                        Invalid: 'This is not a valid Postcode',
                        AddressError: 'Address lookup failed, please enter address manually'
                    },
                    HouseNumber: {
                        maxLength: 'Your House/flat name or number must be 32 characters or less'
                    },
                    PreviousAddress: 'Previous address must be different',
                    LookupAddress: 'Please provide your address'
                },
                FindAddressButton: 'Find address'
            },
            CurrentAddress: {
                Label: 'Your address',
                ListTitle: 'Select your address',
                TimeAtAddressLabel: 'How long have you lived at this address?'
            },
            PreviousAddress: {
                Label: 'Your previous address',
                ListTitle: 'Select your previous address',
                TimeAtAddressLabel: 'How long did you live at this address?'
            },
            LookupAddressLink: 'Or enter address manually',
            ManualAddressLink: 'Or search for your address',
            LoadingMessage: 'Finding your address...'
        },
        Tracker: {
            ActiveStep: 'Step {{index}} - {{title}}',
            FutureStep: 'Step {{index}}'
        },
        SinglePageExperimentTakeControl: 'Take control with an easy to manage card from Capital One'
    },
    Stage: {
        BTNoHarm: {
            text1: `Check if we'll approve your transfer amount, with `,
            text2: 'no impact to your credit score.'
        },
        IncomeMessaging: {
            YearlyIncome: {
                Title: 'Could your income change soon?',
                Description:
                    'If your income could <b>go down</b> in the next 12 months (for example maternity, retirement), please enter what you think it will be.'
            },
            OtherHouseHoldIncome: {
                Title: 'Could your income change soon?',
                Description:
                    'If your income could <b>go down</b> in the next 12 months, please enter what you think it will be.'
            },
            RentMortgagePayment: {
                Title: 'Could your rent or mortgage change soon?',
                Description:
                    'If your rent, mortgage or board could <b>go up</b> in the next 12 months, please enter what you think it will be.'
            }
        },
        SubmissionFooter: {
            Text: 'No impact to your credit score'
        },
        SinglePageExperimentApplyHeader: 'One last thing...'
    },
    QuickcheckGuarantee: {
        NoImpact: 'No impact on your credit score'
    },
    TermsAndConditions: {
        Statement: 'Before doing a QuickCheck please read the {{TERMS_AND_CONDITIONS}} and {{PRIVACY_POLICY}}',
        TAndC: 'terms and conditions',
        PrivacyPolicy: 'privacy policy',
        PrivacyPolicyLink: ExternalLinks.PRIVACY_POLICY,
        TermsAndConditionsHeader: 'Capital One QuickCheck Terms and Conditions'
    },
    Modal: {
        CloseButtonAriaLabel: 'Close pop-up',
        Close: 'close'
    },
    TermsAndConditionsModalView: {
        Text: `
                <ModalHeaderTwo data-qa-id="main-header">Capital One QuickCheck Terms and Conditions</ModalHeaderTwo>
                <ModalHeaderThree data-qa-id="about-us-header">1. About us</ModalHeaderThree>
                <ModalParagraph data-qa-id="about-us-paragraph">
                    Capital One (Europe) plc is a public limited company registered in England and Wales under company number 3879023.
                    In these terms and conditions, "we", "us" and "our" refers to Capital One (Europe) plc.
                    Our registered office is at Trent House, Station Street, Nottingham, NG2 3HX.
                    We are authorised and regulated by the Financial Conduct Authority with reference number 204440.
                </ModalParagraph>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="search-service-header">2. QuickCheck quotation search service</ModalHeaderThree>
                <ModalParagraph data-qa-id="search-service-paragraph">
                    We provide the QuickCheck quotation service. By using QuickCheck, you confirm that you accept these terms.
                    If you do not agree to these terms, you must not use QuickCheck.
                    We recommend that you print a copy of these terms for future reference.
                </ModalParagraph>
                <ModalParagraph data-qa-id="search-service-paragraph2">
                    The QuickCheck quotation service includes:
                </ModalParagraph>
                <ModalList data-qa-id="search-service-list">
                    <ModalListItem data-qa-id="search-service-list-item1">
                        A pre-application check of your credit record and the information you provide,
                        which we use with our own credit scoring methods to work out and tell you if you are
                        eligible for one of our credit products or not;
                    </ModalListItem>
                    <ModalListItem data-qa-id="search-service-list-item2">
                        Confirmation of your eligibility and, where eligible, our credit product suggestions, including by email and/or SMS using the information you provide; and
                    </ModalListItem>
                    <ModalListItem data-qa-id="search-service-list-item3">
                        If you do not successfully apply for one of our credit products:
                    </ModalListItem>
                    <ModalList>
                        <ModalListItem data-qa-id="search-service-list-item3a">
                            where this part of the service is offered with our QuickCheck quotation service,
                            we may do further pre-application checks and let you know whether you're eligible for one of our credit products
                            (unless you tell us you do not want us to do this); and
                        </ModalListItem>
                        <ModalListItem data-qa-id="search-service-list-item3b">
                            we'll send you marketing about our other suitable products, services or relevant information by post,
                        </ModalListItem>
                    </ModalList>
                </ModalList>
                <ModalParagraph data-qa-id="search-service-paragraph3">
                    When we make a pre-application check of your credit record and the information you provide,
                    the Credit Reference Agencies will make a record of our searches that will leave a "footprint" on your credit file.
                    You'll be able to see the search on your file, but it won't show up to other organisations.
                    This means there is no impact to your credit rating.
                </ModalParagraph>
                <ModalParagraph data-qa-id="search-service-paragraph4">
                    Any credit product suggestion(s) we make based on your eligibility is not an offer of credit.
                    While you may apply for a credit product we suggest, that application is subject to fraud, identity and other checks.
                </ModalParagraph>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="privacy-and-cookies-header">3. Our Privacy and Cookies Policies</ModalHeaderThree>
                <ModalParagraph data-qa-id="privacy-and-cookies-paragraph">
                    These terms refer to the following additional terms, which also apply to your use of QuickCheck:
                </ModalParagraph>
                <ModalList data-qa-id="privacy-and-cookies-list">
                    <ModalListItem data-qa-id="privacy-and-cookies-list-item1">
                        Our <ModalLink href="https://www.capitalone.co.uk/compliance/privacy-policy">Privacy Policy</ModalLink>, which sets out the terms on which we process any personal data we collect from you, or that you provide to us.
                    </ModalListItem>
                    <ModalListItem data-qa-id="privacy-and-cookies-list-item2">
                        Our <ModalLink href="https://www.capitalone.co.uk/compliance/cookie-policy">Cookie Policy</ModalLink>, which sets out information about the cookies on our site.
                    </ModalListItem>
                </ModalList>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="duration-header">4. Duration of QuickCheck service</ModalHeaderThree>
                <ModalParagraph data-qa-id="duration-paragraph">
                    The QuickCheck services will continue unless one of the situations set out below or contrary to these terms occurs:
                </ModalParagraph>
                <ModalList data-qa-id="duration-list">
                    <ModalListItem data-qa-id="duration-list-item1">
                        You become a customer;
                    </ModalListItem>
                    <ModalListItem data-qa-id="duration-list-item2">
                        We discover your information is no longer up to date;
                    </ModalListItem>
                    <ModalListItem data-qa-id="duration-list-item3">
                        You tell us you no longer wish to use/receive the QuickCheck service; or
                    </ModalListItem>
                    <ModalListItem data-qa-id="duration-list-item4">
                        We no longer store your data (three years after you use QuickCheck).
                    </ModalListItem>
                </ModalList>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="use-of-services-header">5. Use of QuickCheck services</ModalHeaderThree>
                <ModalParagraph data-qa-id="use-of-services-paragraph">
                    You must be over the age of 18 and live in the UK to use QuickCheck.
                </ModalParagraph>
                <ModalParagraph data-qa-id="use-of-services-paragraph2">
                    QuickCheck is for your personal use only.
                    You must not use it at any time or in any circumstances for individuals other than yourself.
                </ModalParagraph>
                <ModalParagraph data-qa-id="use-of-services-paragraph3">
                    Use of QuickCheck is made available free of charge.
                </ModalParagraph>
                <ModalParagraph data-qa-id="use-of-services-paragraph4">
                    We do not guarantee that the QuickCheck service will always be available or be uninterrupted.
                    We may suspend or withdraw or restrict the availability of all or any part of the QuickCheck service
                    for business and operational reasons.
                </ModalParagraph>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="intellectual-property-header">6. Intellectual Property</ModalHeaderThree>
                <ModalParagraph data-qa-id="intellectual-property-paragraph">
                    All copyright, trademarks and all other intellectual property rights in QuickCheck are owned by us.
                </ModalParagraph>
                <ModalParagraph data-qa-id="intellectual-property-paragraph2">
                    You must not use any part of the content on our site for commercial purposes.
                </ModalParagraph>
                <ModalParagraph data-qa-id="intellectual-property-paragraph3">
                    You must not modify, adapt, copy, download or post such content or information from the
                    QuickCheck service in any other website or forum.
                </ModalParagraph>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="links-to-quickcheck-header">7. Links to QuickCheck</ModalHeaderThree>
                <ModalParagraph data-qa-id="links-to-quickcheck-paragraph">
                    If you wish to link to the QuickCheck service, please contact us.
                </ModalParagraph>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="limitation-of-liability-header">8. Limitation of liability</ModalHeaderThree>
                <ModalParagraph data-qa-id="limitation-of-liability-paragraph">
                    We do not exclude or limit in any way our liability to you where it would be unlawful to do so.
                    This includes liability for death or personal injury caused by our negligence or the negligence of our employees,
                    agents or subcontractors and for fraud or fraudulent misrepresentation.
                </ModalParagraph>
                <ModalParagraph data-qa-id="limitation-of-liability-paragraph2">
                    We will not be responsible for loss or damage unless it is foreseeable,
                    i.e. it results because of our negligence or a breach of these terms.
                </ModalParagraph>
                <ModalParagraph data-qa-id="limitation-of-liability-paragraph3">
                    We are not responsible for viruses or other malicious or technologically harmful material and
                    any content you download from us is at your own risk.
                    You are responsible for configuring your information technology and you should use your own virus protection software.
                </ModalParagraph>
                <ModalParagraph data-qa-id="limitation-of-liability-paragraph4">
                    Our liability for any loss or damage is limited to £100.
                </ModalParagraph>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="contact-us-header">9. Contact us</ModalHeaderThree>
                <ModalParagraph data-qa-id="contact-us-paragraph">
                    You can contact us using the different ways set out at <ModalLink href="https://www.capitalone.co.uk/support/contact-us">https://www.capitalone.co.uk/support/contact-us</ModalLink>.
                </ModalParagraph>
                <ModalParagraph data-qa-id="contact-us-paragraph2">
                    If you want to make a complaint, you can write to: Capital One (Europe) plc, PO Box 5281, Nottingham, NG2 3FA.
                    Alternatively, you can find details of how to do it by visiting <ModalLink href="https://www.capitalone.co.uk">www.capitalone.co.uk</ModalLink>.
                    If your complaint is to do with a Credit Reference Agency or the information we have obtained from them,
                    we may direct it to them when it is appropriate to do so.
                </ModalParagraph>
                <ModalParagraph data-qa-id="contact-us-paragraph3">
                    If you're unhappy with how we have dealt with your complaint, you also have the right to complain to the
                    Financial Ombudsman Service by writing to: The Financial Ombudsman Service, Exchange Tower, London E14 9SR.
                    You can also contact them by telephone on 0800 023 4567 or email at
                    <ModalLink href="mailto:complaint.info@financial-ombudsman.org.uk">complaint.info@financial-ombudsman.org.uk</ModalLink>.
                    You can find further information by visiting <ModalLink external href="http://www.financial-ombudsman.org.uk">www.financial-ombudsman.org.uk</ModalLink>
                </ModalParagraph>
                <ModalParagraphDivider />
                <ModalHeaderThree data-qa-id="general-header">10. General</ModalHeaderThree>
                <ModalParagraph data-qa-id="general-paragraph">
                    We shall not be liable for any delay or failure to provide the services or perform any obligation under
                    these terms if the delay or failure is caused by circumstances beyond our reasonable control.
                </ModalParagraph>
                <ModalParagraph data-qa-id="general-paragraph2">
                    These terms do not give any rights to anyone who is not a party to them.
                    You may not assign your rights or obligations in relation to these terms.
                </ModalParagraph>
                <ModalParagraph data-qa-id="general-paragraph3">
                    If any provision in these terms becomes invalid or unenforceable the validity or enforceability of the
                    other provisions will not be affected.
                </ModalParagraph>
                <ModalParagraph data-qa-id="general-paragraph4">
                    If there is any delay by us in enforcing any of these terms, this will not be treated as a waiver
                    by us of that or any other provision of these terms.
                </ModalParagraph>
                <ModalParagraph data-qa-id="general-paragraph5">
                    Communications we provide to you by us by email or posted on our site will constitute legal service in
                    writing and will be deemed received by you the day after it is sent or posted.
                </ModalParagraph>
                <ModalParagraph data-qa-id="general-paragraph6">
                    This agreement contains the whole agreement between you and us.
                </ModalParagraph>
                <ModalParagraph data-qa-id="general-paragraph7">
                    These terms and our site are governed by English law. You and we both agree to submit to the exclusive jurisdiction of the English and Welsh courts,
                    unless you're a resident of Northern Ireland where you can also bring proceedings in Northern Ireland, and if you're a resident of Scotland
                    you can also bring proceedings in Scotland.
                </ModalParagraph>
                <ModalParagraphDivider />`
    },
    HiddenText: {
        NewTab: ', opens in a new tab',
        NewWindow: ', opens in a new window',
        PopupWindow: ', opens in a popup window',
        CurrentStepReadout: '. Current step.'
    },
    SkipLink: {
        Text: 'Skip to content'
    }
};

export default content;
