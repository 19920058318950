import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Heading,
    IconSummaryPanel,
    IconSummaryPanelList,
    Link,
    List,
    ListItem,
    MeterIcon,
    Text as PText,
    PageRow,
    Paragraph,
    StopwatchIcon,
    THEME_CONSTANTS
} from '@cof/plastic-components';
import { css, styled } from 'styled-components';

import CreditWiseSvg from '../../../../assets/shared/svg/logo_creditwise.svg?react';
import { withText } from '../../../../text/textStore';
import { ImproveIcon } from '../../../Icon';
import MoneyHelperBanner from '../../../MoneyHelperBanner';
import { useFormData } from '../../../FormDataContext';
import { isAggregatorConsumer } from '../../../../utilities/utils';

const CreditWiseLogo = styled(CreditWiseSvg)`
    display: block;
    margin: auto;
    height: 8rem;
`;
CreditWiseLogo.displayName = 'CreditWiseLogo';

const CreditWiseWhatCanYouDoWrapper = styled(Box)`
    margin-bottom: 4rem;
    ${({ theme }) => theme.components.CreditWiseWhatCanYouDoWrapper}
`;

const CreditWiseCreditScoreWrapper = styled(Box)`
    ${({ theme }) => theme.components.CreditWiseCreditScoreWrapper}
`;

const CustomPageRow = styled(PageRow)`
    ${({ theme }) => css`
        padding: ${theme.space.md} ${theme.space.sm};
        ${theme.breakpoints.mq.tabletMin} {
            padding: ${theme.space.md} ${theme.space.md};
        }
    `}
`;
CustomPageRow.displayName = 'CustomPageRow';

const { COLORS } = THEME_CONSTANTS;

const CreditWiseSection = ({ Text, Text: text, JsxText }) => {
    const { formData } = useFormData();
    const consumerName = formData?.consumerName;
    const isAgg = isAggregatorConsumer(consumerName);
    return (
        <>
            <CustomPageRow>
                <Box mt={30}>
                    <Heading level="3" data-qa-id="heading">
                        <Text path="HowDidWeDecide.Heading" />
                    </Heading>
                    <Paragraph>
                        <JsxText
                            path="HowDidWeDecide.Body.Copy"
                            params={{
                                EQUIFAX: (
                                    <Link external href={text({ path: 'HowDidWeDecide.Body.Equifax.Target' })}>
                                        <Text path="HowDidWeDecide.Body.Equifax.Text" />
                                    </Link>
                                ),
                                TRANSUNION: (
                                    <Link external href={text({ path: 'HowDidWeDecide.Body.Transunion.Target' })}>
                                        <Text path="HowDidWeDecide.Body.Transunion.Text" />
                                    </Link>
                                ),
                                EXPERIAN: (
                                    <Link external href={text({ path: 'HowDidWeDecide.Body.Experian.Target' })}>
                                        <Text path="HowDidWeDecide.Body.Experian.Text" />
                                    </Link>
                                )
                            }}
                        />
                    </Paragraph>
                </Box>
            </CustomPageRow>
            <CustomPageRow>
                <CreditWiseWhatCanYouDoWrapper>
                    <Heading level="3" data-qa-id="heading">
                        <Text path="WhatCanYouDo.Heading" />
                    </Heading>
                    {!isAgg && <MoneyHelperBanner />}
                    <List>
                        <ListItem>
                            <Paragraph>
                                <Text path="WhatCanYouDo.List.Item1" />
                            </Paragraph>
                        </ListItem>
                        <ListItem>
                            <Paragraph>
                                <JsxText
                                    path="WhatCanYouDo.List.Item2"
                                    params={{
                                        CREDITWISE: (
                                            <Link external href={text({ path: 'WhatCanYouDo.CreditWise.Target' })}>
                                                <Text path="WhatCanYouDo.CreditWise.Text" />
                                            </Link>
                                        )
                                    }}
                                />
                            </Paragraph>
                        </ListItem>
                    </List>
                </CreditWiseWhatCanYouDoWrapper>
            </CustomPageRow>
            <CustomPageRow wrapperBg={COLORS.global.grey50}>
                <CreditWiseCreditScoreWrapper textAlign="center" py={['md', 'lg', null]} data-qa-id="credit-score">
                    <CreditWiseLogo />
                    <Heading level="3" py={['md', null]} data-qa-id="heading">
                        <Text path="CreditScore.Heading" />
                    </Heading>
                    <IconSummaryPanelList px={[0, 0, 0]}>
                        <IconSummaryPanel
                            heading={text({ path: 'Points.Point1.Heading' })}
                            bg={COLORS.global.white}
                            Icon={MeterIcon}
                        >
                            <PText>{text({ path: 'Points.Point1.Body' })}</PText>
                        </IconSummaryPanel>
                        <IconSummaryPanel
                            heading={text({ path: 'Points.Point2.Heading' })}
                            colorVariant="light"
                            bg={COLORS.global.white}
                            Icon={StopwatchIcon}
                        >
                            <PText>{text({ path: 'Points.Point2.Body' })}</PText>
                        </IconSummaryPanel>
                        <IconSummaryPanel
                            heading={text({ path: 'Points.Point3.Heading' })}
                            colorVariant="light"
                            bg={COLORS.global.white}
                            Icon={ImproveIcon}
                        >
                            <PText>{text({ path: 'Points.Point3.Body' })}</PText>
                        </IconSummaryPanel>
                    </IconSummaryPanelList>
                    <Paragraph pb={['md', null]} data-qa-id="body">
                        <Text path="CreditScore.Body" />
                    </Paragraph>
                    <Button
                        as="a"
                        href={text({ path: 'CreditScore.Button.Target' })}
                        data-qa-id="get-creditwise-button"
                    >
                        <Text path="CreditScore.Button.Text" />
                    </Button>
                </CreditWiseCreditScoreWrapper>
            </CustomPageRow>
        </>
    );
};

CreditWiseSection.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const WrappedCreditWiseSection = withText('ScoreDecline')(CreditWiseSection);

WrappedCreditWiseSection.displayName = 'CreditWiseSection';

export default WrappedCreditWiseSection;
