import { AGGREGATOR_CONSUMER_NAMES, AGGREGATOR_WITH_LOGO, API_PARTNERS, HOSTED_PARTNERS } from './constants';

export const isHosted = (brand) => {
    return HOSTED_PARTNERS.includes(brand);
};

export const hasAggregatorLogo = (consumerName) => {
    return AGGREGATOR_WITH_LOGO.includes(consumerName);
};

export const isAggregatorConsumer = (consumerName) => {
    return AGGREGATOR_CONSUMER_NAMES.includes(consumerName);
};

export const isApiPartner = (brand) => {
    return API_PARTNERS.includes(brand);
};

export const useSinglePage = (brand, consumerName) => {
    return isApiPartner(brand) || isAggregatorConsumer(consumerName);
};

export const lookupWithDefault = (table, key, defaultKey = 'default') => table[key] || table[defaultKey];

export const addSParameterToLinkUrl = ({ url, sParameter }) => {
    const urlObj = new URL(url);
    if (sParameter) {
        urlObj.searchParams.set('s', sParameter);
    }
    return urlObj.toString();
};
