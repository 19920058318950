import { Box, Button, Heading } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

import { OuterCircle } from '../../../components/Hero/Circle';

export const NewMain = styled.main`
    ${({ theme }) => css`
        background-color: ${theme.colors.capitalOne.blue750};
        position: relative;
        z-index: 2;
        ${theme.components.NewMain}
    `}
`;
NewMain.displayName = 'NewMain';

export const EmphasizedTitleText = styled.span`
    ${({ theme }) => css`
        color: ${theme.colors.capitalOne.teal450};
        ${theme.components.EmphasizedTitleText}
    `}
`;
EmphasizedTitleText.displayName = 'EmphasizedTitleText';

export const EnhancedOuterCircle = styled(OuterCircle)`
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMin} {
            right: -68rem;
            height: 82rem;
            width: 82rem;
            top: -18rem;
            display: block;
        }
        ${theme.components.EnhancedOuterCircle}
    `}
`;
EnhancedOuterCircle.displayName = 'EnhancedOuterCircle';

export const EnhancedOuterCircleWrapper = styled.div`
    ${({ theme }) => css`
        margin: auto;
        position: relative;
        width: 73rem;
        display: none;
        ${theme.breakpoints.mq.tabletMin} {
            display: block;
        }
    `}
`;
EnhancedOuterCircleWrapper.displayName = 'EnhancedOuterCircleWrapper';

export const FormSpinnerWrapper = styled.div`
    padding: 10rem 0;
`;
FormSpinnerWrapper.displayName = 'FormSpinnerWrapper';

export const StyledQFHeading = styled(Heading)`
    ${({ theme }) => css`
        color: ${theme.colors.global.white};
        max-width: 45rem;
        ${theme.components.StyledQFHeading}
    `}
`;
StyledQFHeading.displayName = 'StyledQFHeading';

export const StyledBox = styled(Box)`
    ${({ theme }) => css`
        background-color: ${theme.colors.capitalOne.blue750};
        ${theme.components.StyledBox}
    `}
`;
StyledBox.displayName = 'StyledBox';

export const StyledFormWrapper = styled.div`
    ${({ theme }) => css`
        ${theme.components.StyledFormWrapper}
        padding-bottom: ${theme.space.lg};
        position: relative;
        z-index: 3;
        background: ${theme.colors.capitalOne.blue750};
        ${theme.components.StyledFormWrapper}
    `}
`;
StyledFormWrapper.displayName = 'FormWrapper';

export const StyledFormBody = styled.div`
    ${({ theme }) => css`
        box-sizing: content-box;
        margin: auto;
        max-width: 34.5rem;
        z-index: 1;
        padding: 0 ${theme.space.sm};
        position: relative;
        ${theme.breakpoints.mq.tabletMin} {
            max-width: 67.4rem;
        }
    `};
`;
StyledFormBody.displayName = 'FormBody';

export const NewFormBody = styled(StyledFormBody)`
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMin} {
            max-width: 67.4rem;
        }
    `}
`;
NewFormBody.displayName = 'NewFormBody';

export const Overlay = styled.div`
    ${({ theme }) => css`
        background: ${theme.colors.capitalOne.blue750};
        height: 17rem;
        width: 100%;
        position: absolute;
        ${theme.components.Overlay}
    `}
`;
Overlay.displayName = 'Overlay';

export const SubmitButton = styled(Button)`
    ${({ theme }) => css`
        ${theme.components.SubmitButton};
    `}
`;
SubmitButton.displayName = 'SubmitButton';
